const prllx =()=> {



    let elems = document.querySelectorAll("._prllx");



    addParallax =(elem)=> {



        //check / set offset data
        let offs1;
        let offs2;
        isNaN(elem.dataset.prllxOffs1) ? offs1 = 100 : offs1 = Math.abs(elem.dataset.prllxOffs1);
        isNaN(elem.dataset.prllxOffs2) ? offs2 = 100 : offs2 = Math.abs(elem.dataset.prllxOffs2);



        //calculate image position
        const calcImagePosition =()=> {
            let elemTop = elem.getBoundingClientRect().y;//*
            let elemHeight = elem.getBoundingClientRect().height;
            let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            let pictureVerticalPos = offs1 - (viewportHeight - elemTop) / (elemHeight + viewportHeight) * offs2;
            elem.style.objectPosition = 'center ' + pictureVerticalPos + '%';
        }



        //call
        calcImagePosition();



        //call on scroll event
        window.addEventListener('scroll', calcImagePosition);



        //call on window resize event

        let calcImagePositionTimer;

        window.addEventListener('resize', function () {
            clearTimeout(calcImagePositionTimer);
            calcImagePositionTimer = setTimeout(calcImagePosition, 333);
        });

    }



    elems.forEach( element => addParallax(element) );



}