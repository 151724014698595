//detect safari
const detectSafari =()=> {
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    if(isSafari) {
        document.documentElement.setAttribute( 'class', 'safari');
    }
}

//recalculates vh unit
const vhCalc = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // console.log('Recalculated VH unit value is ' + vh + 'px');
};

//smooth scrolling
const smoothScrolling = () => {
    $('a[href*="#"]') // Select all links with hashes
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .not('[href="#menu"]')
        .not('[href^="#conf-tab-"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 400, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        }
                    });
                }
            }
        });
}

//tabs
const tabs = (selector) => {
    $(selector + " ._tab-nav a").first().addClass('checked');
    $(selector + " ._tab-content").css('display', 'none');
    $(selector + " ._tab-content").first().addClass('active').css('display', 'block');
    $(selector + " ._tab-nav a").click(function (e) {
        e.preventDefault();
        $(selector + " ._tab-nav a").removeClass('checked');
        $(this).addClass('checked');
        $(selector + " ._tab-content").removeClass('active').css('display', 'none');
        $($(this).attr('href')).addClass('active').css('display', 'block');
    });
}

//intersection observer setup
const intersectionObserverSetup =()=> {
    const ioTargets = document.querySelectorAll('._io, ._io-1, ._io-2, ._io-3');

    function handleIntersection(entries) {
      
      entries.map((entry) => {
        // entry.target.classList.add('_hidden')
        if (entry.isIntersecting) {
        //   console.log(entry + '--- intersecting happend');
          entry.target.classList.add('_visible')
          } else {
            entry.target.classList.remove('_visible')
          }
        
      });
    }
    
    const observer = new IntersectionObserver(handleIntersection);
    
    ioTargets.forEach(elem => observer.observe(elem));
    //intersection observer
}

// create mmenu
const mmenuInit = () => {
    new Mmenu("#mainmenu", {
        "slidingSubmenus": false,
        "extensions": [
            "pagedim-black",
            "position-right"
        ],
        "navbars": [
            {
                "position": "bottom",
                "content": [
                    '<ul class="grid grid-cols-3 gap-4 justify-items-center items-center w-full max-w-xs mx-auto py-4"> <li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-whatsup"></use> </svg> </a> </li><li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-viber"></use> </svg> </a> </li><li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-messenger"></use> </svg> </a> </li><li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-phone"></use> </svg> </a> </li><li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-mail"></use> </svg> </a> </li><li class="w-12 h-12 overflow-hidden rounded-full p-1 bg-gray-300"> <a href="#" class="text-white"> <svg class="svg-icon "> <use xlink:href="#i-facebook"></use> </svg> </a> </li></ul>'
                ]
                // "content": [
                //    "<a class='fa fa-envelope' href='#/'></a>",
                //    "<a class='fa fa-twitter' href='#/'></a>",
                //    "<a class='fa fa-facebook' href='#/'></a>"
                // ]
            }
        ],
        hooks: {
            "openPanel:start": (panel) => {
                // console.log("Started opening pane: " + panel.id);
            },
            "open:start": () => {
                // console.log("kinyilt a menu");
                if ($('.swiper-container').length) {
                    swiperBig.update();
                }

            },
            "close:start": () => {
                // console.log("bezarodott menu");
                if ($('.swiper-container').length) {
                    swiperBig.update();
                }
            },
            "open:finish": () => {
                // console.log("kinyilt a menu");
                if ($('.swiper-container').length) {
                    swiperBig.update();
                }

            },
            "close:finish": () => {
                // console.log("becsukodott a menu");
                if ($('.swiper-container').length) {
                    swiperBig.update();
                }
            }
        }
    });

}

//create swiper
const swiperInit=()=>{
    var swiperBig = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
}

//create pushbar
let pushbar;
const pushbarInit=(command)=>{
    if (command == "close") {
        if (typeof pushbar !== 'undefined') {
            pushbar.close();
            return;
        } else {
            return;
        }
    } else {
        pushbar = new Pushbar({
            blur:true,
            overlay:true,
          });
    }
}

//closes pushbar above breakpoint if opened
const pushbarClose = () => {
    if (window.innerWidth >= 1280 && $('.pushbar.opened').length) {
        pushbarInit('close');
    }
}

// toggle ui elements
const toggleUiElements=()=> {
    $('._toggleswitch span[role="checkbox"]').on('click keypress', function (e) {
        if (e.type == 'click' || e.key == 'Enter') {
            $(this).toggleClass(['off', 'on']);
        }
    });
    $('._toggle-extras').on('click keypress', function (e) {
        if (e.type == 'click' || e.key == 'Enter') {
            $(this).toggleClass(['off', 'on']);
        }
    });
    $('._radio-extras').on('click keypress', function (e) {
        if (e.type == 'click' || e.key == 'Enter') {
            $(this).addClass('on').removeClass('off').siblings().removeClass('on').addClass('off');
        }
    });
}

//create lightgallery
const lightGalleryInit = () => {
    if ($("#lightgallery").length) {
        // console.log('light gallery starting...');
        $("#lightgallery").lightGallery({
            download: false,
        });
        // console.log('light gallery initiated');
    } else {
        // console.log('light gallery was not found .______.');
    }
}

//create menuSpy
const menuSpyInit = () => {
    var elm = document.querySelector('#faqnav');
    var ms = new MenuSpy(elm, {
        activeClass: 'current-item',
        treshhold: 500
    });
}



