console.log(consoleGreatingsText);

const scriptsRunOnce = () => {

  //call functions here which will run once;
  detectSafari();
  vhCalc();
  smoothScrolling();
  tabs('._configurator-tabs');
  intersectionObserverSetup();
  pushbarInit();
  mmenuInit();
  swiperInit();
  lightGalleryInit();
  menuSpyInit();
  toggleUiElements();
  prllx();

  //don't touch below ;-)
  setTimeout(scriptsOnWinResize, 333);

}



const scriptsOnWinResize = () => {

  //call functions here which will run once + on window resize event
  pushbarClose();

  //don't touch below ;-)
  scriptsOnScroll();

}



const scriptsOnScroll = () => {

      //call functions here which will run once + on window resize event + on window scroll event


}



const scriptsOnScrollStrict = () => {

  //call functions here which will run stricktly on window scroll event


}



$(function () {

  //every script runs once - - - - - - - - - - - -

  scriptsRunOnce();



  //window scroll event - - - - - - - - - - - -

  window.addEventListener('scroll', function () {
    scriptsOnScroll();
    scriptsOnScrollStrict();
  });



  //window resize event - - - - - - - - - - - -

  let scriptsOnWinResizeTimer;
  window.addEventListener('resize', function () {
    clearTimeout(scriptsOnWinResizeTimer);
    scriptsOnWinResizeTimer = setTimeout(scriptsOnWinResize, 333);
  });



  //other stuff - - - - - - - - - - - -

  $('#mainmenu').removeAttr('style');//nem emlekszem, hogy ez miert kell :-(

  //add letters to slider nav
  $('span[aria-label="Go to slide 1"]').append('s');
  $('span[aria-label="Go to slide 2"]').append('3');
  $('span[aria-label="Go to slide 3"]').append('x');
  $('span[aria-label="Go to slide 4"]').append('y');



});